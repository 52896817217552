<template>
  <v-scroll-y-transition mode="out-in">
    <div
      v-if="rule.length === 0"
      class="title grey--text text--lighten-1 font-weight-light mx-auto"
      style="align-self: center"
    >
      Selectionnez une catégorie et une règle
    </div>
    <v-card v-else flat class="pa-4">
      <v-row>
        <v-col cols="12">
          <v-chip
            color="primary lighten-1"
            label
            text-color="white"
            >{{ rule.scope }}</v-chip
          >
        </v-col>
        <v-col cols="12"
          ><span class="font-weight-medium">{{ rule.name }}</span></v-col
        >
      </v-row>

      <v-divider />

      <v-row>
        <v-col cols="12" v-if="rule.description">
          <p class="font-weight-bold accent--text">Définition :</p>
          {{ rule.description }}
        </v-col>
        <v-col cols="12" v-if="rule.value">
          <p class="font-weight-bold accent--text">Valeur :</p>
          {{ rule.value }}
        </v-col>
        <v-col cols="12" v-if="rule.financial_risk">
          <p class="font-weight-bold accent--text">Risque Financier :</p>
          {{ rule.financial_risk }}
        </v-col>
        <v-col cols="12" v-if="rule.meta">
          <p class="font-weight-bold accent--text">Causes probables et exemples purement illustratifs et non exhaustifs :</p>
          <div v-for="(usecase, i) in rule.meta" :key="i">- {{ usecase }}</div>
        </v-col>
      </v-row>
    </v-card>
  </v-scroll-y-transition>
</template>

<script>
export default {
  data: () => ({
    rule: [],
  }),
  mounted() {
    this.$root.$on("printDetails", (data) => {
      this.rule = data;
    });
  },
};
</script>